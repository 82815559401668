import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "@/assets/global.css";
import VueApexCharts from "vue-apexcharts";
import contenteditable from "vue-contenteditable"
import Vue2Editor from "vue2-editor";
import BlotFormatter from "quill-blot-formatter";
import * as Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import "quill-mention";

Vue2Editor.Quill.register("modules/emoji", Emoji);
Vue2Editor.Quill.register("modules/blotFormatter", BlotFormatter);

Vue.prototype.$quill = Vue2Editor.Quill;
Vue.use(Vue2Editor);
Vue.use(contenteditable)
Vue.config.productionTip = false;
Vue.use(VueApexCharts);
Vue.component("apexChart", VueApexCharts);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
