const toastr = require("toastr");
const toastrOptions = { transitionIn: "fadeIn", transitionOut: "fadeOut", position: "top-right", progressBar: true };

const emitToastr = (type = "error", title = "", message = "") => {
  return toastr[type](title, message, toastrOptions);
};

const applyColor = (colorName) => {
  const defaultColors = {
    login_background_color: "#EFF1F4",
    login_left_background_color: "#EFF1F4",
    login_primary_text_color: "#000000",
    login_secondary_text_color: "#8C8C9B",
    login_button_text_color: "#FFFFFF",
    login_button_background_color: "#C24A6B",

    menu_primary_text_color: "#FFFFFF",
    menu_secondary_text_color: "#9f9fa8",
    menu_background_color: "#242434",
    menu_button_background_color: "#C24A6B",

    header_primary_text_color: "#252535",
    header_secondary_text_color: "#3f4255",
    header_background_color: "#FFFFFF",
    header_menu_button_color: "#252535",
    header_search_bar_text_color: "#a3a4aa",
    header_search_bar_background_color: "#FFFFFF",

    pages_primary_text_color: "#000000",
    pages_secondary_text_color: "#787a85",
    pages_background_color: "#EFF1F4",
    pages_button_text_color: "#FFFFFF",
    pages_button_background_color: "#C24A6B",
    pages_text_field_background_color: "#FFFFFF",
    pages_card_primary_text_color: "#252535",
    pages_card_secondary_text_color: "#a8a8b4",
    pages_card_background_color: "#FFFFFF",
    favorite_icon_border_color: "#FFFFFF",
    favorite_icon_active_color: "#FF2F2F",
  };

  return defaultColors[colorName] || "#FFFFFF";
};

function idify(title) {
  return title
    .replace(/[-!$%^&*#()_+|~=`{}@\[\]:";'<>?,.\/]/g, '')
    .replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
    .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    .trim();
};

export { emitToastr, applyColor, idify };
