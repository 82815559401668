import axios from "axios";
import { emitToastr } from "./Utils";

const API_URL = process.env.NODE_ENV === "development" ? "http://localhost:5001" : "https://api.academiajoco.com.br";

class Api {
  login = async (email, password, remember) => {
    const endpoint = "/admin/auth";
    let result = await this.authPost(endpoint, { email, password, remember });

    if (result && result.token && result.user) {
      window.localStorage.setItem("token", result.token);
      delete result.token;
      window.localStorage.setItem("user", JSON.stringify(result.user));
      result.login_ok = true;
    }

    return result;
  };

  logout = async () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    window.location.href = "/login";
  };

  getUser = () => {
    let user = window.localStorage.getItem("user");
    try {
      user = JSON.parse(user);
      return user;
    } catch {
      return {};
    }
  };

  getToken = () => {
    return window.localStorage.getItem("token") || "";
  };

  getRemoteUser = async () => {
    const user = await this.authGet("/admin/get-user", {}, {});

    if (user && user._id) {
      window.localStorage.setItem("user", JSON.stringify(user));
      return user;
    }
  };

  getUserProjects = async () => {
    const projects = await this.authPost("/admin/get-user-projects", {}, {});
    return projects;
  };

  getProjectByFriendlyId = async (friendly_id) => {
    const project_data = await this.authPost("/admin/get-project-by-friendly-id", { friendly_id: friendly_id }, {});
    return project_data;
  };

  getClusters = async (course_id) => {
    const resp = await this.authPost("/admin/get-clusters", { course_id: course_id }, {});
    return resp;
  };

  getClustersTotalUsers = async (course_id, project_id) => {
    const resp = await this.authPost("/admin/get-clusters-total-users", { course_id, project_id }, {});
    return resp;
  };

  getClustersByProjectId = async (project_id) => {
    const resp = await this.authPost("/admin/get-clusters-by-project-id", { project_id }, {});
    return resp;
  };

  getClustersByCourseId = async (course_id, project_id) => {
    const resp = await this.authPost("/admin/get-clusters-by-course-id", { project_id, course_id, project_id }, {});
    return resp;
  };

  getClusterById = async (cluster_id) => {
    const resp = await this.authPost("/admin/get-cluster-by-id", { cluster_id: cluster_id }, {});
    return resp;
  };

  getObjectsByTrails = async (trails, project_id) => {
    const resp = await this.authPost("/admin/get-objects-by-trails", { trails, project_id }, {});
    return resp;
  };

  createOrUpdateHeadsByProjectId = async ({ project_id, heads }) => {
    const resp = await this.authPost("/admin/create-or-update-heads", { project_id, heads }, {});
    return resp;
  };

  getLandingPages = async (friendly_id) => {
    const resp = await this.authPost("/admin/get-landing-pages", { friendly_id: friendly_id }, {});
    return resp;
  };

  getLandingHTML = async (landing_id) => {
    const resp = await this.get(`/get-landing-page-html?landing_id=${landing_id}&API_URL=${API_URL}`, {}, {});
    return resp;
  };

  createLandingPage = async (friendly_id, landing) => {
    const resp = await this.authPost("/admin/create-landing-page", { friendly_id: friendly_id, landing: landing }, {});
    return resp;
  };

  getCoursesByProjectId = async (project_id) => {
    const projects = await this.authPost("/admin/get-courses-by-project-id", { project_id: project_id }, {});
    return projects;
  };

  createOrUpdateCourse = async (friendly_id, course) => {
    const resp = await this.authPost("/admin/create-or-update-course", { friendly_id: friendly_id, course: course }, {});
    return resp;
  };

  deleteCourse = async (friendly_id, course) => {
    await this.authPost("/admin/delete-course", { friendly_id: friendly_id, course: course }, {});
    return;
  };

  getObjectById = async (friendly_id, obj_id) => {
    const resp = await this.authPost("/admin/get-object-by-id", { friendly_id, obj_id }, {});
    return resp;
  }

  getObjectsByCourseId = async (course_id) => {
    const resp = await this.authPost("/admin/get-objects-by-course-id", { course_id: course_id }, {});
    return resp;
  };

  getObjectsByFriendlyId = async (friendly_id) => {
    const resp = await this.authPost("/admin/get-objects-by-project-id", { friendly_id: friendly_id }, {});
    return resp;
  };

  createOrUpdateObject = async (friendly_id, object, trail_setup_options) => {
    const resp = await this.authPost("/admin/create-or-update-object", { friendly_id, object, trail_setup_options }, {});
    return resp;
  };

  cloneObject = async (friendly_id, obj_id, title) => {
    const resp = await this.authPost("/admin/clone-object", { friendly_id: friendly_id, obj_id, title }, {});
    return resp;
  };

  deleteObject = async (friendly_id, object) => {
    await this.authPost("/admin/delete-object", { friendly_id: friendly_id, object: object }, {});
    return;
  };

  createOrUpdatePreviewObject = async (object) => {
    const resp = await this.authPost("/admin/create-or-update-preview-object", object, {});
    return resp;
  };

  getHerosByCourseId = async (course_id) => {
    const resp = await this.authPost("/admin/get-heros-by-course-id", { course_id: course_id }, {});
    return resp;
  };

  createOrUpdateHero = async (friendly_id, hero) => {
    const resp = await this.authPost("/admin/create-or-update-hero", { friendly_id: friendly_id, hero: hero }, {});
    return resp;
  };

  deleteHero = async (friendly_id, hero) => {
    await this.authPost("/admin/delete-hero", { friendly_id: friendly_id, hero: hero }, {});
    return;
  };

  createOrUpdateCluster = async (course_id, cluster) => {
    const resp = await this.authPost("/admin/create-or-update-cluster", { course_id, cluster }, {});
    return resp;
  };

  deleteCluster = async (friendly_id, cluster) => {
    await this.authPost("/admin/delete-cluster", { friendly_id: friendly_id, cluster: cluster }, {});
    return;
  };

  listTrails = async (course_id) => {
    const resp = await this.authPost("/admin/list-trails", { course_id }, {});
    return resp;
  }

  getTrail = async (trail_id) => {
    const resp = await this.authPost("/admin/get-trail", { trail_id }, {});
    return resp;
  }

  createOrUpdateTrail = async (course_id, trail) => {
    const resp = await this.authPost("/admin/create-or-update-trail", { course_id: course_id, trail: trail }, {});
    return resp;
  };

  deleteTrail = async (trail_id) => {
    return await this.authPost("/admin/delete-trail", { trail_id });
  };

  mergeTrail = async (cluster_ids, trail_ids, trail_target_id) => {
    return await this.authPost("/admin/merge-trails", { cluster_ids, trail_ids, trail_target_id });
  }

  changeListOrder = async (friendly_id, cluster_id, trail, type, list) => {
    const resp = await this.authPost("/admin/change-list-order", { friendly_id: friendly_id, cluster_id: cluster_id, trail: trail, type: type, list: list }, {});
    return resp;
  };

  recoveryPassword = async (email) => {
    const resp = await this.authPost("/admin/send-password-recovery", { email: email }, {});
    return resp;
  };

  changePassword = async (email, password, recovery_token) => {
    const resp = await this.authPost("/admin/change-password", { email, password, recovery_token }, {});
    return resp;
  };

  changeUserPassword = async (_id, password, recovery_token) => {
    const resp = await this.authPost("/admin/change-user-password", { _id, password, recovery_token }, {});
    return resp;
  };

  updateLandingPage = async (friendly_id, landing_page) => {
    const resp = await this.authPost("/admin/update-landing-page", { friendly_id: friendly_id, landing_page: landing_page }, {});
    return resp;
  };

  getReportSummary = async (project_id, course_id, cluster_id, days_before) => {
    const projects = await this.authPost("/admin/get-report-summary", { project_id, course_id, cluster_id, days_before }, {});
    return projects;
  };

  getTalentsReports = async (project_id, days_before, cluster_id) => {
    const projects = await this.authPost("/admin/get-talents-reports", { project_id, days_before, cluster_id }, {});
    return projects;
  };

  getTalentsContactsSheet = async (project_id, days_before, cluster_id) => {
    const projects = await this.authPost("/admin/get-talent-contact-sheet", { project_id, days_before, cluster_id }, { arraybuffer: true });
    return projects;
  };

  getForumReports = async (project_id, course_id, days_before, cluster_id) => {
    const projects = await this.authPost("/admin/get-forum-reports", { project_id, course_id, days_before, cluster_id }, {});
    return projects;
  };

  getReportUsersByFilter = async (filter, project_id, multi, course_id, sheets = false) => {
    const resp = await this.authPost("/admin/get-report-users-by-filter", { filter: filter, project_id: project_id, multi: multi, course_id: course_id, sheets: sheets }, { arraybuffer: sheets ? true : false });
    return resp;
  };

  getReportLearningTestUsersByFilter = async (filter, project_id) => {
    const resp = await this.authPost("/admin/get-report-learning-test-users-by-filter", { filter: filter, project_id: project_id }, { });
    return resp;
  };

  getEnrollmentsByUserId = async (user_id) => {
    const resp = await this.authPost("/admin/get-enrollments-by-user-id", { user_id: user_id }, {});
    return resp;
  };

  getObjectTemplates = async (project_id) => {
    const resp = await this.authPost("/admin/get-object-templates", { project_id: project_id }, {});
    return resp;
  }

  getClusterReport = async (project_id) => {
    const resp = await this.authPost("/admin/get-cluster-report", { project: project_id }, {});
    return resp;
  };

  getRatingReport = async (project_id, course_id, cluster_id, interval) => {
    const resp = await this.authPost("/admin/get-rating-report", { project_id, course_id, cluster_id, days_before: interval }, {});
    return resp;
  }

  getRatingReportSheet = async (project_id, course_id, cluster_id, interval) => {
    const resp = await this.authPost("/admin/get-rating-report-sheet", { project_id, course_id, cluster_id, days_before: interval }, { arraybuffer: true });
    return resp;
  }

  uploadImage = async (friendly_id, data, url = undefined) => {
    let postData = { friendly_id, data };
    if (url) postData = { friendly_id, data, url };

    const resp = await this.authPost("/admin/image-upload", postData, { multipart: true, formData: true });
    return resp;
  };

  createOrUpdateCertificateTemplate = async (friendly_id, content, title, _id) => {
    let resp = await this.authPost("/admin/create-or-update-certificate-template", { friendly_id, content, title, _id }, {});
    return resp;
  }

  listCertificateTemplates = async (friendly_id) => {
    let resp = await this.authPost("/admin/list-certificate-templates", { friendly_id }, {});
    return resp;
  }

  getCertificateTemplate = async (friendly_id, _id) => {
    let resp = await this.authPost("/admin/get-certificate-template", { friendly_id, _id }, {});
    return resp;
  }

  getCertificate = async (_id) => {
    return axios
      .get(`${process.env.NODE_ENV === "development" ? "http://localhost:5001/utils" : "https://api.academiajoco.com.br/utils"}/certificate/${_id}`, {})
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          if (err.response.data && err.response.data.logoff === true) this.logout();
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) return emitToastr("error", err.response.data.message);
          console.log("Erro genérico de servidor");
        }
      })
  }

  deleteCertificateTemplate = async (friendly_id, _id) => {
    let resp = await this.authPost("/admin/delete-certificate-template", { friendly_id, _id }, {});
    return resp;
  }

  getOpenAIText = async (text, change_size, change_tone) => {
    let resp = await this.authPost("/admin/get-openai-text", { text, change_size, change_tone }, {});
    if (resp && !resp.error && resp.message && resp.message.charAt(0) === '"' && resp.message.charAt(resp.message.length - 1) === '"') {
      resp.message = resp.message.substring(1, resp.message.length - 1);
    }
    return resp;
  };

  getOpenAIImage = async (text) => {
    let resp = await this.authPost("/admin/get-openai-image", { text }, {});
    return resp;
  };

  createOrUpdateForumTopic = async (friendly_id, object) => {
    const resp = await this.authPost("/admin/create-or-update-forum-topic", { friendly_id, object }, {});
    return resp;
  };

  getForumTopicsByCourseId = async (friendly_id, course_id) => {
    const resp = await this.authPost("/admin/get-forum-topics-by-course-id", { friendly_id, course_id }, {});
    return resp;
  };

  getObjectConsumptionByCluster = async (friendly_id, course_id, cluster_id, objects) => {
    const resp = await this.authPost("/admin/get-object-consumption-by-cluster", { friendly_id, course_id, cluster_id, objects }, {});
    return resp;
  };

  getObjectTestsByCluster = async (friendly_id, cluster_id, objects) => {
    const resp = await this.authPost("/admin/get-object-tests-by-cluster", { friendly_id, cluster_id, objects }, {});
    return resp;
  };

  getSheetObjectConsumptionByCluster = async (friendly_id, course_id, cluster_id, objects) => {
    const resp = await this.authPost("/admin/get-sheet-object-consumption-by-cluster", { friendly_id, course_id, cluster_id, objects }, { arraybuffer: true });
    return resp;
  };

  getSheetObjectTestsByCluster = async (friendly_id, cluster_id, objects, filter, question_id) => {
    const resp = await this.authPost("/admin/get-sheet-object-tests-by-cluster", { friendly_id, cluster_id, objects, filter, question_id }, { arraybuffer: true });
    return resp;
  }

  getCertsByTrail = async (clusters, interval) => {
    const resp = await this.authPost("/admin/get-certs-by-trail", { clusters, interval });
    return resp;
  }

  getCertsSheet = async (clusters, interval) => {
    const resp = await this.authPost("/admin/get-certs-sheet", { clusters, interval }, { arraybuffer: true });
    return resp;
  }

  genTextAI = async (current_text, type, context, options) => {
    const resp = await this.authPost("/admin/gen-text-ai", { current_text, type, context, options }, {});
    return resp;
  }

  uploadMedia = async (friendly_id, data) => {
    const subfolder = `joco-lms/${friendly_id}`;

    const resp = await axios
      .post(`https://api.joco.com.br/admin/send-to-storage`, { friendly_id, subfolder, data }, { headers: { "Content-Type": "multipart/form-data" } })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) {
            return emitToastr("error", err.response.data.message);
          } else if (err.response && err.response.status) emitToastr("error", err.response.statusText + " (status: " + err.response.status + ")", "Erro");
          else emitToastr("error", "Erro genérico de servidor!", "Erro");
          err.response.error = err.response.error || true;
          return err.response;
        }
      });

    return resp;
  };

  createOrUpdateProject = async (project) => {
    const resp = await this.authPost("/admin/create-or-update-project", { project }, {});
    return resp;
  };

  deleteProject = async (project) => {
    project.filed = true;
    const resp = await this.authPost("/admin/create-or-update-project", { project }, {});
    return resp;
  };

  createUser = async (user) => {
    const resp = await this.authPost("/admin/create-admin-user", user, {});
    return resp;
  };

  createDeepLink = async (cluster, trail, object, label) => {
    const resp = await this.authPost("/admin/gen-deep-link", { cluster, trail, object, label }, {});
    return resp;
  };

  listDeepLink = async (obj_id) => {
    const resp = await this.authPost("/admin/list-deep-link", { obj_id }, {});
    return resp;
  }

  getEmailsEnrollments = async (course, emails) => {
    const resp = await this.authPost("/admin/get-emails-enrollment", { course, emails }, {});
    return resp;
  }

  migrateEnrollments = async (course, cluster, emails) => {
    const resp = await this.authPost("/admin/migrate-enrollments", { course, cluster, emails }, {});
    return resp;
  }

  checkErrorsWithStatusCode200 = (data, download) => {
    if (data.error) return emitToastr("error", data.message || "Erro não identificado!", "Erro");
    return data;
  };

  get = (url) =>
    axios
      .get(`${API_URL}${url}`)
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch(() => {
        emitToastr("error", "Erro genérico de servidor: servidor pode estar em manutenção!", "Erro");
      });

  authGet = (url, data) =>
    axios
      .get(`${API_URL}${url}`, { headers: { auth: this.getToken() }, params: data })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          if (err.response.data && err.response.data.logoff === true) this.logout();
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) return emitToastr("error", err.response.data.message);
          console.log("Erro genérico de servidor: servidor pode estar em manutenção!");
        }
      });

  authPost = (url, data, options) => {
    const headers = { auth: this.getToken() };
    if (options) {
      if (options.multipart) {
        headers["Content-Type"] = "multipart/form-data";
      }
      if (options.formData) {
        let formData = new FormData();
        for (let key in data) {
          formData.append(key, data[key]);
        }
        data = formData;
      }
    }
    return axios
      .post(`${API_URL}${url}`, data, { headers, responseType: options && options.arraybuffer ? 'arraybuffer' : undefined })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        console.error(err.response);
        if (err && err.response && err.response.status === 401) {
          emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          if (err.response.data && err.response.data.logoff === true) this.logout();
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) {
            return emitToastr("error", err.response.data.message);
          } else if (err.response && err.response.status) emitToastr("error", err.response.statusText + " (status: " + err.response.status + ")", "Erro");
          else emitToastr("error", "Erro genérico de servidor: servidor pode estar em manutenção!", "Erro");
          err.response.error = err.response.error || true;
          return err.response;
        }
      });
  };
}

export default new Api();
