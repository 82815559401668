import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/password-recovery",
    name: "password-recovery",
    component: () => import("../views/PasswordRecovery.vue"),
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/user-password-recovery",
    name: "user-password-recovery",
    component: () => import("../views/UserPasswordRecovery.vue"),
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/certificate/:id",
    name: "certificate",
    component: () => import('@/views/CertificateView.vue'),
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/",
    name: "Projetos",
    component: () => import("../views/ProjectsView.vue"),
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/:project/:course/dashboard",
    name: "Dashboard",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/:project/customizations",
    name: "Customizações",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/Customizations.vue"),
  },
  {
    path: "/:project/colors",
    name: "Cores",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/Colors.vue"),
  },
  {
    path: "/ds",
    name: "Design System",
    meta: {
      layout: "fullscreen",
    },
    component: () => import("../views/DS.vue"),
  },
  {
    path: "/:project/heads",
    name: "Heads",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/Heads.vue"),
  },
  {
    path: "/:project/landing_page",
    name: "Landing Page",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/LandingPage.vue"),
  },
  {
    path: "/homologacao/:landing_id",
    name: "Landing Page - Homologação",
    meta: {
      layout: "fullscreen",
    },
    component: () => import("../views/LandingHML.vue"),
  },
  {
    path: "/:project/:course/certificate/edit",
    name: "Editar Template de Certificado",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/CertificateEditor.vue"),
  },
  {
    path: "/:project/:course/certificates",
    name: "Certificados",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/Certificates.vue"),
  },
  {
    path: "/:project/:course/clusters",
    name: "Clusters",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/Clusters.vue"),
  },
  {
    path: "/:project/:course/trails",
    name: "Trilhas",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/Trails.vue"),
  },
  {
    path: "/:project/:course/cluster/:cluster",
    name: "Cluster",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/Cluster.vue"),
  },
  {
    path: "/:project/courses",
    name: "Cursos",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/Courses.vue"),
  },
  // {
  //   path: "/:project/cluster/:cluster/:trail",
  //   name: "Trilha",
  //   meta: {
  //     layout: "menu",
  //   },
  //   component: () => import("../views/Trail.vue"),
  // },
  {
    path: "/:project/:course/trail/:trail",
    name: "Trilha",
    meta: {
      layout: "menu",
    },
    component: () => import('../views/Trail.vue'),
  },
  {
    path: "/:project/:course/learning_objects",
    name: "Objetos de Aprendizagem",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/LearningObjects.vue"),
  },
  {
    path: "/:project/:course/object_creator/:obj_id?",
    name: "Criador de Objetos",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/ObjectCreator.vue"),
  },
  {
    path: "/:project/:course/heros",
    name: "Heros",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/Heros.vue"),
  },
  {
    path: "/:project/reports_ga",
    name: "Relatórios Google Analytics",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/ReportsGA.vue"),
  },
  {
    path: "/:project/:course/reports_users",
    name: "Relatórios de Usuários",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/ReportsUsers.vue"),
  },
  {
    path: "/:project/:course/reports_user",
    name: "Relatório de Usuário",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/ReportUser.vue"),
  },
  {
    path: "/:project/:course/reports_content_by_groups",
    name: "Relatório de Grupos de Usuários",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/ReportsContentByGroups.vue"),
  },
  {
    path: "/:project/:course/reports_clusters",
    name: "Relatório de Clusters de Usuário",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/ReportsClusters.vue"),
  },
  {
    path: "/:project/:course/reports_learning_tests",
    name: "Relatórios de testes de aprendizagem",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/ReportLearningTestByGroups.vue"),
  },
  {
    path: "/:project/:course/reports_learning_tests_by_user",
    name: "Relatórios de testes de aprendizagem",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/ReportLearningTestByUser.vue"),
  },
  {
    path: "/:project/:course/reports_certs",
    name: "Relatórios de Certificados",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/ReportCertsByGroups.vue"),
  },
  {
    path: "/:project/talents",
    name: "Banco de Talentos",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/Talents.vue"),
  },
  {
    path: "/:project/:course/reports_bt",
    name: "Relatório de Banco de Talentos",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/ReportsBT.vue"),
  },
  {
    path: "/:project/:course/report_rating",
    name: "Relatório de Avaliação de Objetops",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/ReportsRating.vue"),
  },
  {
    path: "/:project/:course/reports_forum",
    name: "Relatório de Fórum",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/ReportsForum.vue"),
  },
  {
    path: "/:project/:course/forum",
    name: "Fórum",
    meta: {
      layout: "menu",
    },
    component: () => import("../views/Forum.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/login", "/password-recovery", "/user-password-recovery"];
  const authRequired = !publicPages.includes(to.path) && !to.path.includes("/homologacao/") && !to.path.startsWith('/certificate');
  const loggedIn = localStorage.getItem("token");

  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  if (to)
    Vue.nextTick(() => {
      document.title = to.name ? to.name + ' | Academia JOCO ' : "Admin";
    });
});

export default router;
