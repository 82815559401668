<template>
  <v-app style="background-color: #eaeaea">
    <v-main v-if="$route.meta.layout === 'fullscreen'">
      <router-view></router-view>
    </v-main>

    <div v-if="$route.meta.layout === 'menu'">
      <v-progress-linear app indeterminate color="deep-purple lighten-3" class="loading-bar"
        v-show="showLoading"></v-progress-linear>

      <v-navigation-drawer class="drawer" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" width="270" app>
        <center class="avatar_container pa-4 mt-4">
          <div class="avatar w-100">
            <img :src="project.image" style="object-fit: contain; width: 100%; max-height: 8rem" />
          </div>

          <div>
            <p class="white--text mb-1" style="font-size: 0.75rem; text-align: start; width: 100%">
              Curso
            </p>
            <v-select class="select-course" dense v-model="course" color="#c24a6b" :items="courses" item-text="name"
              background-color="#1B1B2A" return-object filled outlined @change="load">
              <template #selection="{ item }">
                <span style="color: #a8a8ae">{{ item.name }}</span>
              </template>
            </v-select>
          </div>

          <div>
            <v-list dense>
              <v-list-item link @click="openSite">
                <v-list-item-action class="item_icon">
                  <v-icon class="icon">mdi-web</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title class="menu-item" style="text-align: left">Acessar Academia</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </center>

        <v-divider class="mt-2 mx-4 menu-divider" color="white"></v-divider>

        <v-list dense class="px-2">
          <v-list-item-group v-model="selectedItem" class="selected-item" :mandatory="true">
            <v-list v-if="hasPermission('analytics')">
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-title class="menu-title">ANÁLISE DE DADOS</v-list-item-title>
                </template>

                <template v-slot:appendIcon>
                  <v-icon color="#C24A6B">mdi-chevron-up</v-icon>
                </template>

                <v-subheader class="v-subheader">Visão geral</v-subheader>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/dashboard'">
                  <v-list-item link>
                    <v-list-item-action class="item_icon">
                      <v-icon class="icon">mdi-monitor-dashboard</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="menu-item">Academia</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/report_rating'">
                  <v-list-item link>
                    <v-list-item-action class="item_icon">
                      <v-icon class="icon">mdi-star</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="menu-item">
                        Avaliações
                        <v-chip small>novo</v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/reports_bt'"
                  v-if="project.module_talents === true">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-briefcase-account-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Banco de talentos</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/reports_forum'"
                  v-if="project.module_forum === true">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-comment-text-multiple-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Fórum</v-list-item-title>
                  </v-list-item>
                </router-link>

                <v-divider class="my-2 mx-3 menu-divider" color="white"></v-divider>
                <v-subheader class="v-subheader">Consumo de conteúdo</v-subheader>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/reports_content_by_groups'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-content-copy</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Objetos de aprendizagem</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/reports_clusters'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-account-group-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Clusters de usuários</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/reports_certs'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-certificate</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Certificados <v-chip small>novo</v-chip></v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/reports_users'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-account-group-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Segmentos cadastrados</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/reports_user'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-account-search</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Individual</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link to="//analytics.joco.com.br/" target="_blank">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-cube</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Jornadas gameficadas</v-list-item-title>
                  </v-list-item>
                </router-link>

                <v-divider class="my-2 mx-3 menu-divider" color="white"></v-divider>
                <v-subheader class="v-subheader">Testando aprendizagem por</v-subheader>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/reports_learning_tests'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-content-copy</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Objetos de aprendizagem</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/reports_learning_tests_by_user'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-account-search</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Individuo</v-list-item-title>
                  </v-list-item>
                </router-link>
              </v-list-group>
            </v-list>

            <v-divider v-if="hasPermission('analytics')" class="mx-3 menu-divider" color="white"></v-divider>

            <v-list v-if="hasPermission('content')">
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-title class="menu-title">EDIÇÃO DE CONTEÚDO</v-list-item-title>
                </template>

                <template v-slot:appendIcon>
                  <v-icon color="#C24A6B">mdi-chevron-up</v-icon>
                </template>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/learning_objects'
                  ">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-book-multiple-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Objetos de Aprendizagem</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/heros'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-view-day-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Heros</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/clusters'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-account-group-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Clusters</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/trails'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-play-box-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Trilhas</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/certificates'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-certificate</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Certificados <v-chip small>novo</v-chip></v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/' + course?._id + '/forum'"
                  v-if="project.module_forum === true">
                  <v-list-item link>
                    <v-list-item-action class="item_icon">
                      <v-icon class="icon">mdi-forum-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="menu-item">Temas do fórum</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>
              </v-list-group>
            </v-list>

            <v-divider class="mx-3 menu-divider" color="white" v-if="hasPermission('content')"></v-divider>

            <v-list v-if="hasPermission('content')">
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-title class="menu-title">CUSTOMIZAÇÕES GERAIS</v-list-item-title>
                </template>

                <template v-slot:appendIcon>
                  <v-icon color="#C24A6B">mdi-chevron-up</v-icon>
                </template>

                <router-link :to="'/' + friendly_id + '/colors'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-palette</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Cores da página, botões e etc</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/heads'">
                  <v-list-item>
                    <v-list-item-icon class="item_icon">
                      <v-icon class="icon">mdi-web</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Heads - favicon, título, keywords</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/customizations'" v-if="user && user.system_admin">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon class="icon">mdi-dev-to</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title class="menu-item">Configurações de dev</v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/courses'">
                  <v-list-item link>
                    <v-list-item-action class="item_icon">
                      <v-icon class="icon">mdi-book-education-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="menu-item">Cursos</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/landing_page'">
                  <v-list-item link>
                    <v-list-item-action class="item_icon">
                      <v-icon class="icon">mdi-application-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="menu-item">Landing Page</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>

                <router-link :to="'/' + friendly_id + '/talents'" v-if="project.module_talents === true">
                  <v-list-item link>
                    <v-list-item-action class="item_icon">
                      <v-icon class="icon">mdi-account-group-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title class="menu-item">Banco de Talentos</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </router-link>
              </v-list-group>
            </v-list>
          </v-list-item-group>
        </v-list>

        <div class="logo">
          <img src="@/assets/logo_joco.png" width="55" height="50" />
        </div>
      </v-navigation-drawer>

      <v-main>
        <v-app-bar color="white" :clipped-left="$vuetify.breakpoint.lgAndUp" dark elevation="2">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer" color="black"></v-app-bar-nav-icon>
          <v-toolbar-title class="toolbar">
            {{ $route.name }}

            <br />
            <span class="toolbar-subtitle">{{ projectName }}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-menu>
            <template #activator="{ on: menu }">
              <v-btn icon v-on="menu">
                <v-icon color="black">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item style="cursor: pointer" href="/">
                <v-list-item-title>Projetos</v-list-item-title>
              </v-list-item>
              <v-list-item style="cursor: pointer" @click="logout()">
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
        <v-container fluid class="menu-page-container">
          <router-view fluid />
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Api from "@/lib/Api";

export default {
  name: "App",

  data: () => ({
    drawer: false,
    showLoading: false,
    selectedItem: 0,
    projectName: "",
    friendly_id: "",
    user: {},
    project: {},
    objects: [],
    courses: [],
    course: null,
    intervals: [
      { title: "Últimos 7 dias", id: 7 },
      { title: "Últimos 15 dias", id: 15 },
      { title: "Últimos 1 mês", id: 30 },
      { title: "Últimos 3 meses", id: 90 },
      { title: "Últimos 6 meses", id: 180 },
      { title: "Últimos 1 ano", id: 365 },
      { title: "Desde o início", id: 365 * 99 },
    ],
  }),

  methods: {
    hasPermission(permission) {
      if (this.user && this.user.permissions)
        return this.user.permissions.indexOf(permission) != -1
      return false;
    },

    logout() {
      Api.logout();
    },

    load() {
      this.$router.replace({ 'params': { course: this.course._id } })
      this.$root.$emit("project_loaded");
      // this.$router.replace({ name: "Dashboard" }).catch(() => { });
    },

    setLoading(loading) {
      this.showLoading = loading;
    },

    async getProjectInfo(friendly_id) {
      this.setLoading(true);
      const project = await Api.getProjectByFriendlyId(friendly_id);
      this.courses = await Api.getCoursesByProjectId(project._id, friendly_id);
      if (project && project.name) this.projectName = project.name;
      this.project = project;
      this.friendly_id = friendly_id;
      if (!this.course) this.course = this.courses[0];
      this.$root.$emit("project_loaded");
      this.setLoading(false);
    },

    async updateProject(friendly_id) {
      this.setLoading(true);
      const project = await Api.getProjectByFriendlyId(friendly_id);
      this.project = project;
      this.$root.$emit("project_loaded");
      this.setLoading(false);
    },

    openSite() {
      window.open(`https://${this.project.domain}`, "_blank");
    },
  },

  created() {
    this.$root.$refs.global = this;
  },

  async mounted() {
    this.user = Api.getUser();
    if (this.user && this.user._id) await Api.getRemoteUser();


    if (this.$route.params && this.$route.params.project) {
      this.getProjectInfo(this.$route.params.project);
    }

    this.$vuetify.breakpoint.lgAndUp
      ? (this.drawer = true)
      : (this.drawer = false);
  },

  watch: {
    "$route.params": {
      handler: async function (newparams, oldparams) {
        console.log("UPDATED ROUTE PARAMS");
        this.setLoading(false);
        if (newparams && newparams.project) {
          if (oldparams && oldparams.project == newparams.project) return;
          await this.getProjectInfo(newparams.project);
        }
        if (!this.courses.find(d => d._id == this.$route.params.course)) {
          this.$router.replace({ params: { course: this.courses[0]._id } })
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.menu-page-container {
  background-color: #eaeaea;
}

.select-course {
  color: white !important;
}

.v-list-item-group .v-list-item--active {
  color: $rosa_escuro;
}

.v-select__selection,
.v-select__selection--comma,
.v-select.v-text-field input {
  color: red !important;
}

.menu-divider {
  opacity: 0.2;
  text-align: center;
}

.toolbar {
  color: $roxo_escuro;
  font-weight: 700;
  line-height: 19px;
}

.drawer {
  background-color: $roxo_escuro;
  z-index: 100;
}

.icon {
  color: #b0b0b0;
}

.item_icon {
  margin-right: 16px !important;
}

.v-subheader {
  color: #787878 !important;
  font-size: 16px;
  font-weight: 400;
}

.logo {
  position: relative;
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.menu-title {
  color: $rosa_escuro;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.menu-item {
  color: white;
  font-weight: 400 !important;
  text-overflow: none !important;
}

.loading-bar {
  position: absolute;
  z-index: 99999 !important;
}

.avatar_container {
  margin-bottom: 10px;
}

.avatar {
  margin-top: 15px;
  margin-bottom: 10px;
}

.rotate_icon {
  transform: rotate(90deg);
}

.toolbar-subtitle {
  font-size: 16px;
  font-weight: 500;
}
</style>
